var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.order && _vm.order.id)?_c('div',{staticClass:"timeline-container position-relative"},[_c('div',{staticClass:"header position-absolute p-4 display-flex align-center"},[_vm._v(" Status ")]),_c('div',{staticClass:"timeline p-4"},[_c('v-timeline',{attrs:{"dense":"","clipped":""}},[_c('v-timeline-item',{staticClass:"mb-4",class:{
            active: _vm.log.items['Order placed'],
          },attrs:{"color":_vm.log.items['Order placed'] ? '#FF003F' : 'grey',"small":""}},[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"status"},[_vm._v("Order Placed")]),_c('div',{staticClass:"status-details mt-2"},[_vm._v(" "+_vm._s(_vm.log.items["Order placed"] ? _vm.formatTimelineDate(_vm.log.items["Order placed"].created_at) : "")+" ")])]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"4"}},[(_vm.order.productdetails && _vm.order.productdetails.id)?_c('v-tooltip',{attrs:{"max-width":"300px","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('div',_vm._g({staticClass:"product-img exact-center mr-2"},on),[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.order.productdetails.product_image),expression:"order.productdetails.product_image"}],attrs:{"src":(_vm.serverUrl + "/" + (_vm.order.productdetails.product_image)),"alt":"product-image"}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.order.productdetails.product_image),expression:"!order.productdetails.product_image"}],attrs:{"src":require("@/assets/icons/product.svg"),"alt":"product-image"}})])]}}],null,false,2832113103)},[_c('div',[_vm._v(_vm._s(_vm.order.productdetails.product_name))])]):_vm._e()],1)],1)],1),_c('v-timeline-item',{staticClass:"mb-4",class:{
            active: _vm.log.items['Parcel ready for shipping'],
          },attrs:{"color":_vm.log.items['Parcel ready for shipping'] ? '#FF003F' : 'grey',"small":""}},[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"7"}},[_c('div',{staticClass:"status"},[_vm._v("Parcel ready for shipping")]),_c('div',{staticClass:"status-details mt-2"},[_vm._v(" "+_vm._s(_vm.log.items["Parcel ready for shipping"] ? _vm.formatTimelineDate( _vm.log.items["Parcel ready for shipping"].created_at ) : "")+" ")])]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"5"}})],1)],1),(!_vm.log.items['Parcel picked by logistics'])?_c('v-timeline-item',{staticClass:"mb-4 active-class",class:{
            active: _vm.log.items['Parcel picked by logistics to Customer'],
          },attrs:{"color":_vm.log.items['Parcel picked by logistics to Customer']
              ? '#FF003F'
              : 'grey',"small":""}},[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"status"},[_vm._v("Parcel Picked by Logistics to Customer")]),(_vm.log.items['Parcel picked by logistics to Customer'])?_c('div',{staticClass:"status-details mt-2"},[_vm._v(" Driver Name: "+_vm._s(_vm.log.items["Parcel picked by logistics to Customer"] ? _vm.log.items["Parcel picked by logistics to Customer"] .content : "-")+" ")]):_vm._e(),_c('div',{staticClass:"status-details mt-2"},[_vm._v(" "+_vm._s(_vm.log.items["Parcel picked by logistics to Customer"] ? _vm.formatTimelineDate( _vm.log.items["Parcel picked by logistics to Customer"] .created_at ) : "")+" ")])]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"4"}},[(_vm.order.logisticdetails && _vm.order.logisticdetails.id)?_c('v-tooltip',{attrs:{"max-width":"300px","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('div',_vm._g({staticClass:"product-img exact-center mr-2"},on),[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.order.logisticdetails.company_img),expression:"order.logisticdetails.company_img"}],attrs:{"src":(_vm.serverUrl + "/" + (_vm.order.logisticdetails.company_img)),"alt":"product-image"}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.order.logisticdetails.company_img),expression:"!order.logisticdetails.company_img"}],attrs:{"src":require("@/assets/icons/warehouse-dummy.svg"),"alt":"product-image"}})])]}}],null,false,1103504647)},[_c('div',[_vm._v(_vm._s(_vm.order.logisticdetails.company_name))])]):_vm._e()],1)],1)],1):_vm._e(),(_vm.log.items['Parcel picked by logistics'])?_c('v-timeline-item',{staticClass:"mb-4 active-class",class:{
            active: _vm.log.items['Parcel picked by logistics'],
          },attrs:{"color":_vm.log.items['Parcel picked by logistics'] ? '#FF003F' : 'grey',"small":""}},[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"status"},[_vm._v("Parcel Picked by Logistics")]),(_vm.log.items['Parcel picked by logistics'])?_c('div',{staticClass:"status-details mt-2"},[_vm._v(" Driver Name: "+_vm._s(_vm.log.items["Parcel picked by logistics"] ? _vm.log.items["Parcel picked by logistics"].content : "-")+" ")]):_vm._e(),_c('div',{staticClass:"status-details mt-2"},[_vm._v(" "+_vm._s(_vm.log.items["Parcel picked by logistics"] ? _vm.formatTimelineDate( _vm.log.items["Parcel picked by logistics"].created_at ) : "")+" ")])]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"4"}},[(_vm.order.logisticdetails && _vm.order.logisticdetails.id)?_c('v-tooltip',{attrs:{"max-width":"300px","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('div',_vm._g({staticClass:"product-img exact-center mr-2"},on),[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.order.logisticdetails.company_img),expression:"order.logisticdetails.company_img"}],attrs:{"src":(_vm.serverUrl + "/" + (_vm.order.logisticdetails.company_img)),"alt":"product-image"}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.order.logisticdetails.company_img),expression:"!order.logisticdetails.company_img"}],attrs:{"src":require("@/assets/icons/warehouse-dummy.svg"),"alt":"product-image"}})])]}}],null,false,1103504647)},[_c('div',[_vm._v(_vm._s(_vm.order.logisticdetails.company_name))])]):_vm._e()],1)],1)],1):_vm._e(),(_vm.log.items['Parcel picked by logistics'])?_c('v-timeline-item',{staticClass:"mb-4",class:{
            active: _vm.log.items['Parcel at sorting center'],
          },attrs:{"color":_vm.log.items['Parcel at sorting center'] ? '#FF003F' : 'grey',"small":""}},[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"7"}},[_c('div',{staticClass:"status"},[_vm._v("Parcel at sorting center")]),_c('div',{staticClass:"status-details mt-2"},[_vm._v(" "+_vm._s(_vm.log.items["Parcel at sorting center"] ? _vm.formatTimelineDate( _vm.log.items["Parcel at sorting center"].created_at ) : "")+" ")])]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"5"}})],1)],1):_vm._e(),(_vm.log.items['Parcel picked by logistics'])?_c('v-timeline-item',{staticClass:"mb-4",class:{
            active: _vm.log.items['Parcel picked by logistics to Customer'],
          },attrs:{"color":_vm.log.items['Parcel picked by logistics to Customer'] ? '#FF003F' : 'grey',"small":""}},[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"7"}},[_c('div',{staticClass:"status"},[_vm._v("Order out to delivery")]),(_vm.log.items['Parcel picked by logistics to Customer'])?_c('div',{staticClass:"status-details mt-2"},[_vm._v(" Driver Name: "+_vm._s(_vm.log.items["Parcel picked by logistics to Customer"] ? _vm.log.items["Parcel picked by logistics to Customer"].content : "-")+" ")]):_vm._e(),_c('div',{staticClass:"status-details mt-2"},[_vm._v(" "+_vm._s(_vm.log.items["Parcel picked by logistics to Customer"] ? _vm.formatTimelineDate( _vm.log.items["Parcel picked by logistics to Customer"].created_at ) : "")+" ")])]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"5"}})],1)],1):_vm._e(),_c('v-timeline-item',{staticClass:"mb-4",class:{
            active: _vm.log.items['Order delivered'],
          },attrs:{"color":_vm.log.items['Order delivered'] ? '#FF003F' : 'grey',"small":""}},[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"7"}},[_c('div',{staticClass:"status"},[_vm._v("Order Delivered")]),_c('div',{staticClass:"status-details mt-2"},[_vm._v(" "+_vm._s(_vm.log.items["Order delivered"] ? _vm.formatTimelineDate( _vm.log.items["Order delivered"].created_at ) : "")+" ")])]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"5"}})],1)],1)],1)],1)]):_c('div',{staticClass:"exact-center flex-col no-order-container"},[_c('img',{staticClass:"mb-6",attrs:{"src":require("@/assets/icons/no-order.svg"),"alt":"no-order-content"}}),_c('h1',[_vm._v("Track your Order")]),_c('h2',{staticClass:"mt-4"},[_vm._v(" Please enter your Tracking ID above and Track the order! ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }