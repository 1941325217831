import Vue from "vue";

export const getAddressData = async (postalcode, callback) => {
  Vue.$geocoder.setDefaultMode("address");
  try {
    let latLngObj = {
      zip_code: postalcode,
    };
    await Vue.$geocoder.send(latLngObj, async ({ results, status }) => {
      const address = {};
      if (
        status == window?.google?.maps?.GeocoderStatus?.OK ||
        status == "OK"
      ) {
        const address_components = results[0].address_components;
        address_components.forEach((element) => {
          address[element.types[0]] = element.long_name;
        });
        address.fullAddress = setAddress(address);
        const latLng = results[0].geometry.location;
        callback(address, latLng);
      }
    });
  } catch (e) {
    console.log(e);
    callback({});
  }
};

export const getAddressFromLatLng = async ({ lat, lng }, callback) => {
  Vue.$geocoder.setDefaultMode("lat-lng");
  try {
    let latLngObj = {
      lat: lat,
      lng: lng,
    };
    await Vue.$geocoder.send(latLngObj, async ({ results, status }) => {
      const address = {};
      if (status == window.google.maps.GeocoderStatus.OK) {
        const address_components = results[0].address_components;
        address_components.forEach((element) => {
          address[element.types[0]] = element.long_name;
        });
        address.fullAddress = setAddress(address);
        callback(address);
      }
    });
  } catch (e) {
    callback({});
  }
};

const setAddress = (address) => {
  let fullAddress = "";
  if (address.street_number)
    fullAddress = address.neighborhood
      ? fullAddress + `${address.street_number}, `
      : fullAddress + `${address.street_number}`;
  if (address.route)
    fullAddress = address.neighborhood
      ? fullAddress + `${address.route}, `
      : fullAddress + `${address.route}`;
  if (address.neighborhood)
    fullAddress = fullAddress + `${address.neighborhood}`;
  return fullAddress;
};
