<template>
  <div>
    <div class="timeline-container position-relative" v-if="order && order.id">
      <div class="header position-absolute p-4 display-flex align-center">
        Status
      </div>
      <div class="timeline p-4">
        <v-timeline dense clipped>
          <v-timeline-item
            class="mb-4"
            :class="{
              active: log.items['Order placed'],
            }"
            :color="log.items['Order placed'] ? '#FF003F' : 'grey'"
            small
          >
            <v-row justify="space-between">
              <v-col cols="8">
                <div class="status">Order Placed</div>
                <div class="status-details mt-2">
                  {{
                    log.items["Order placed"]
                      ? formatTimelineDate(log.items["Order placed"].created_at)
                      : ""
                  }}
                </div>
              </v-col>
              <v-col class="text-right" cols="4">
                <v-tooltip
                  max-width="300px"
                  v-if="order.productdetails && order.productdetails.id"
                  bottom
                >
                  <template v-slot:activator="{ on }">
                    <div class="product-img exact-center mr-2" v-on="on">
                      <img
                        v-show="order.productdetails.product_image"
                        :src="
                          `${serverUrl}/${order.productdetails.product_image}`
                        "
                        alt="product-image"
                      />
                      <img
                        v-show="!order.productdetails.product_image"
                        src="@/assets/icons/product.svg"
                        alt="product-image"
                      />
                    </div>
                  </template>
                  <div>{{ order.productdetails.product_name }}</div>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-timeline-item>

          <v-timeline-item
            class="mb-4"
            :class="{
              active: log.items['Parcel ready for shipping'],
            }"
            :color="log.items['Parcel ready for shipping'] ? '#FF003F' : 'grey'"
            small
          >
            <v-row justify="space-between">
              <v-col cols="7">
                <div class="status">Parcel ready for shipping</div>
                <div class="status-details mt-2">
                  {{
                    log.items["Parcel ready for shipping"]
                      ? formatTimelineDate(
                          log.items["Parcel ready for shipping"].created_at
                        )
                      : ""
                  }}
                </div>
              </v-col>
              <v-col class="text-right" cols="5"> </v-col>
            </v-row>
          </v-timeline-item>

          <v-timeline-item
            class="mb-4 active-class"
            v-if="!log.items['Parcel picked by logistics']"
            :class="{
              active: log.items['Parcel picked by logistics to Customer'],
            }"
            :color="
              log.items['Parcel picked by logistics to Customer']
                ? '#FF003F'
                : 'grey'
            "
            small
          >
            <v-row justify="space-between">
              <v-col cols="8">
                <div class="status">Parcel Picked by Logistics to Customer</div>
                <div class="status-details mt-2" v-if="log.items['Parcel picked by logistics to Customer']">
                  Driver Name:
                  {{
                    log.items["Parcel picked by logistics to Customer"]
                      ? log.items["Parcel picked by logistics to Customer"]
                          .content
                      : "-"
                  }}
                </div>
                <div class="status-details mt-2">
                  {{
                    log.items["Parcel picked by logistics to Customer"]
                      ? formatTimelineDate(
                          log.items["Parcel picked by logistics to Customer"]
                            .created_at
                        )
                      : ""
                  }}
                </div>
              </v-col>
              <v-col class="text-right" cols="4">
                <v-tooltip
                  max-width="300px"
                  v-if="order.logisticdetails && order.logisticdetails.id"
                  bottom
                >
                  <template v-slot:activator="{ on }">
                    <div class="product-img exact-center mr-2" v-on="on">
                      <img
                        v-show="order.logisticdetails.company_img"
                        :src="
                          `${serverUrl}/${order.logisticdetails.company_img}`
                        "
                        alt="product-image"
                      />
                      <img
                        v-show="!order.logisticdetails.company_img"
                        src="@/assets/icons/warehouse-dummy.svg"
                        alt="product-image"
                      />
                    </div>
                  </template>
                  <div>{{ order.logisticdetails.company_name }}</div>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-timeline-item>

          <v-timeline-item
            class="mb-4 active-class"
            v-if="log.items['Parcel picked by logistics']"
            :class="{
              active: log.items['Parcel picked by logistics'],
            }"
            :color="
              log.items['Parcel picked by logistics'] ? '#FF003F' : 'grey'
            "
            small
          >
            <v-row justify="space-between">
              <v-col cols="8">
                <div class="status">Parcel Picked by Logistics</div>
                <div class="status-details mt-2" v-if="log.items['Parcel picked by logistics']">
                  Driver Name:
                  {{
                    log.items["Parcel picked by logistics"]
                      ? log.items["Parcel picked by logistics"].content
                      : "-"
                  }}
                </div>
                <div class="status-details mt-2">
                  {{
                    log.items["Parcel picked by logistics"]
                      ? formatTimelineDate(
                          log.items["Parcel picked by logistics"].created_at
                        )
                      : ""
                  }}
                </div>
              </v-col>
              <v-col class="text-right" cols="4">
                <v-tooltip
                  max-width="300px"
                  v-if="order.logisticdetails && order.logisticdetails.id"
                  bottom
                >
                  <template v-slot:activator="{ on }">
                    <div class="product-img exact-center mr-2" v-on="on">
                      <img
                        v-show="order.logisticdetails.company_img"
                        :src="
                          `${serverUrl}/${order.logisticdetails.company_img}`
                        "
                        alt="product-image"
                      />
                      <img
                        v-show="!order.logisticdetails.company_img"
                        src="@/assets/icons/warehouse-dummy.svg"
                        alt="product-image"
                      />
                    </div>
                  </template>
                  <div>{{ order.logisticdetails.company_name }}</div>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-timeline-item>

          <v-timeline-item
            v-if="log.items['Parcel picked by logistics']"
            class="mb-4"
            :class="{
              active: log.items['Parcel at sorting center'],
            }"
            :color="log.items['Parcel at sorting center'] ? '#FF003F' : 'grey'"
            small
          >
            <v-row justify="space-between">
              <v-col cols="7">
                <div class="status">Parcel at sorting center</div>
                <div class="status-details mt-2">
                  {{
                    log.items["Parcel at sorting center"]
                      ? formatTimelineDate(
                          log.items["Parcel at sorting center"].created_at
                        )
                      : ""
                  }}
                </div>
              </v-col>
              <v-col class="text-right" cols="5"> </v-col>
            </v-row>
          </v-timeline-item>

          <v-timeline-item
            v-if="log.items['Parcel picked by logistics']"
            class="mb-4"
            :class="{
              active: log.items['Parcel picked by logistics to Customer'],
            }"
            :color="log.items['Parcel picked by logistics to Customer'] ? '#FF003F' : 'grey'"
            small
          >
            <v-row justify="space-between">
              <v-col cols="7">
                <div class="status">Order out to delivery</div>
                <div class="status-details mt-2" v-if="log.items['Parcel picked by logistics to Customer']">
                  Driver Name:
                  {{
                    log.items["Parcel picked by logistics to Customer"]
                      ? log.items["Parcel picked by logistics to Customer"].content
                      : "-"
                  }}
                </div>
                <div class="status-details mt-2">
                  {{
                    log.items["Parcel picked by logistics to Customer"]
                      ? formatTimelineDate(
                          log.items["Parcel picked by logistics to Customer"].created_at
                        )
                      : ""
                  }}
                </div>
              </v-col>
              <v-col class="text-right" cols="5"> </v-col>
            </v-row>
          </v-timeline-item>

          <v-timeline-item
            class="mb-4"
            :class="{
              active: log.items['Order delivered'],
            }"
            :color="log.items['Order delivered'] ? '#FF003F' : 'grey'"
            small
          >
            <v-row justify="space-between">
              <v-col cols="7">
                <div class="status">Order Delivered</div>
                <div class="status-details mt-2">
                  {{
                    log.items["Order delivered"]
                      ? formatTimelineDate(
                          log.items["Order delivered"].created_at
                        )
                      : ""
                  }}
                </div>
              </v-col>
              <v-col class="text-right" cols="5"> </v-col>
            </v-row>
          </v-timeline-item>
        </v-timeline>
      </div>
    </div>
    <div class="exact-center flex-col no-order-container" v-else>
      <img
        class="mb-6"
        src="@/assets/icons/no-order.svg"
        alt="no-order-content"
      />
      <h1>Track your Order</h1>
      <h2 class="mt-4">
        Please enter your Tracking ID above and Track the order!
      </h2>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { SERVER_URL } from "../../../../constants";

export default {
  name: "TrackingCard",
  props: ["order", "log"],
  components: {},
  data() {
    return {
      serverUrl: SERVER_URL,
      markers: [{ position: { lat: 11.0168, lng: 76.9558 } }],
      path: [
        { lat: 11.0168, lng: 76.9558 },
        { lat: 11.6643, lng: 78.146 },
      ],
      active: false,
    };
  },
  methods: {
    formatTimelineDate(date) {
      return moment(date).format("Do MMM,YYYY h:mm a");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";

.timeline-container {
  .blur-class {
    opacity: 0.5;
  }
  .active-class {
    opacity: 1;
  }
  .header {
    background: $white-color;
    height: 20px;
    z-index: 10;
    width: 100%;
    font-weight: 500;
    font-size: 1rem;
    color: #000000;
  }
  .product-img {
    width: 50px;
    height: 50px;
    border: 1px solid #ebebeb;
    border-radius: 5px;
    padding: 12px 6px;
    img {
      width: 100%;
    }
  }
  .timeline {
    height: 710px;
    overflow-y: scroll;
    margin-top: 10px;
  }
  .status {
    font-weight: 500;
    font-size: 1rem;
    color: $secondary-color;
  }
  .status-details {
    font-size: 0.875rem;
    color: #999da0;
  }
}
.no-order-container {
  padding: 0 2.2rem;
  height: 100vh;
  h1 {
    font-weight: 500;
    font-size: 18px;
  }
  h2 {
    text-align: center;
    color: #b5b5c3;
    font-size: 14px;
    font-weight: normal;
  }
}
</style>
